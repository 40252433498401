import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Hero.css';

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="HeroWrapper">
      <div className="HeroTitleWrapper">
        {/* <p className="HeroTitle">
          {t('Доступ до повної версії веб-сайту буде надано після повної оплати послуг розробника')}
        </p> */}

        <p className="HeroTitle">{t('Знижка -30% на професійну чистку зубів')}</p>
        <Link to="/discounts" exact="true">
          <button className="HeroBtn">{t('Детальніше')}</button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;

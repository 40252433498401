import React from 'react';
import './FooterContacts.css';

const FooterContacts = () => {
  return (
    <div className="FooterMobTabContactsWrapper">
      <a className="FooterMobTabContacts" href="tel:+0671540333">
        (067) 154 0333
      </a>
      <a className="FooterMobTabContacts" href="mailto:2940530@gmail.com">
        E-mail: 2940530@gmail.com
      </a>
    </div>
  );
};

export default FooterContacts;

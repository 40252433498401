import { useTranslation } from 'react-i18next';
import sprite from '../../images/sprite.svg';
import './Contacts.css';

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <div className="ContactsWrapper">
      <div className="ContactsLocation__Wrapper">
        <a
          className="Contacts__link-location"
          href="https://maps.app.goo.gl/zfPunEMdA3eRSsmm9"
          target="_blank"
        >
          <svg className="Contacts__icon-location">
            <use href={sprite + '#icon-location'} width="24" height={24}></use>
          </svg>
          <span className="Contacts__link-location-text">{t('Київ, вул.Ентузіастів, 39')}</span>
          <br />
          <span className="Contacts__link-location-text">{t('м.Лівобережна')}</span>
        </a>
      </div>
      <div className="ContactsPhone__Wrapper">
        <svg className="Contacts__icon-smartphone">
          <use href={sprite + '#icon-smartphone-2'} width="28"></use>
        </svg>
        <a className="Contacts__link-Phone" href="tel:+0671540333">
          (067) 154 0333
        </a>
      </div>
      {/* <a className="Contacts__link-Email" href="mailto:2940530@gmail.com">
        <svg className="Contacts__icon-envelope">
          <use href={sprite + '#icon-envelope'} width="24"></use>
        </svg>
        2940530@gmail.com
      </a> */}
    </div>
  );
};

export default Contacts;
